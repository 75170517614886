
.main_cost {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.nav1 {
  position: fixed;
  max-width:30%; 
  height: auto;
  background-color: transparent;
  list-style: none;
  z-index: 1;
  display:none;
  margin-left: 62%;
  border-radius: 20px;
}

.nav1 li {
  margin-top: 15px; 
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 25px;
}

.nav1 li:last-child {
  margin-right: 0; 
}

.nav1 li a {
  color: black; 
  text-decoration: none; 
  font-weight: bold; 
  justify-content: center;
  align-items: center;
  display: flex;
}

.nav1 li a:hover {
  color: #ffc107; 
}

.ppp {
  width: 100%;
}



.form__group {
  margin-bottom: 20px;
}

.mt-2form {
  align-items: center;
  justify-content: space-between;
  width: 100%;
  min-height: 200px;
  padding-top: 20px;
  padding-bottom: 20px;
}

.form1,.form2{
  width: 100%;
}

.form__label {
  display: block;
  margin-bottom: 15px;
  font-weight: bold;
}



.form__field {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
}

.input-wrapper {
  position: relative;
}

.input-wrapper input[type='number']::-webkit-inner-spin-button,
.input-wrapper input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}


.btn {
  padding: 20px 30px;

  cursor: pointer;
  text-align: center;
  text-decoration: none;
  background-color: #007bff;
  color: #fff;
  font-size: 16px;
}




@media screen and (max-width: 768px) {
  .main_cost {
    padding: 10px;
  }

  .form__field {
    font-size: 14px;
  }
}


.selectric-wrapper {
  position: relative;
}

.selectric-form__field {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
}

.card-header button {
  width: 100%;
  text-align: left;
}

.form-container {
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.form-group {
  margin-bottom: 20px;
}

label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.ffff{
  background-color: rgba(211, 189, 189, 0.687);
  overflow: hidden;
  border: 20px solid transparent;
  border-radius: 20px ;
}



button {
  background-color: #007bff;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}

@media (max-width: 768px) {
  .form-container {
      padding: 10px;
  }
}
