.haa{
  margin-top: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 50px;
  font-size: calc(1vw + 2rem);
}

.Pawar {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

h2{
  font-size: calc(1vw + 1rem);
}

.p0p{
  /* width: 40vw; */
  flex-wrap: wrap;
}

.pix{
  max-width: 50vw;
}

@media screen and (max-width: 768px) {
  .Pawar {
    font-size: calc(1vw + 1rem);
    font-size: calc(1vw + 1rem);
    height: max-content;
    width: 100%;
  }
}

.logo {
  width: 60px;
  height: auto;
  filter: invert(500%);
}

.nav1 {
  position: fixed;
  max-width: 30%;
  height: auto;
  background-color: transparent;
  list-style: none;
  z-index: 1;
  overflow: hidden;
  display: flex;
  margin-top: -51px;
  margin-left: 45% !important;
  border-radius: 20px;
  color: black; 
}

.nav1 li {
  margin-top: 15px;
  color: black; 
  text-decoration: none; 
  font-size: 18px;
  font-family: Roboto, Helvetica, sans-serif
}

.nav1 li:last-child {
  margin-right: 0; 
}

.nav1 li a {
  color: black;
  text-decoration: none;
  font-weight: bold;
}

.nav1 li a:hover {
  color: #ffc107;
}

.form-container {
  background-color: rgba(211, 189, 189, 0.687);
  overflow: hidden;
  border: 10px solid transparent;
  border-radius: 20px;
  padding: 20px;
}

input[type="text"],
input[type="email"],
textarea {
  width: 100%;
  padding: 10px;
  border: 2px solid #ccc;
  border-radius: 5px;
  margin-bottom: 10px;
}

button {
  background-color: #007bff;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}

@media (max-width: 768px) {
  .nav1 {
    max-width: 80%; 
  }
  .Pawar {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
  }
  .pix{
    max-width: 80vw;
  }
  .nav1{
    margin-left: 45em !important;
  }
}

@media (max-width: 468px) {
  .nav1 {
    max-width: 90%;
  }
}

@media (max-width: 320px) {
  .nav1 {
    max-width: 100%;
  }
}
