* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

#main {
  width: 100%;
  font-family: Roboto, Helvetica, sans-serif;
  background-color: #ffffffbd;
}

#container {
  width: 100%;
  overflow: hidden;
  position: relative;
}

.silde1,
.silde2 {
  display: flex;
  animation: slide 16s infinite;
}

.silde .home {
  width: 100%;
  display: flex;
}

.silde .home img {
  width: 100%;
  height: auto;
}

.logo1 {
  width: 20vw;
  filter: brightness(1%);
}
.servs li link{
  text-decoration: none; 
  font-weight: bold;
}
#Container2,
#Container3 {
  display: flex;
  justify-content: space-evenly;
  margin-top: 1%;
}

h1 {
  color: rgb(255, 111, 0);
  font-family: 'Red Hat Display', sans-serif;
  margin-left: 1px;
}
.h111{
  width: 21%;
  color: rgb(255, 111, 0);
  font-family: 'Red Hat Display', sans-serif;
  margin-right: 90%;
}

.dodo{
  margin-right: 78%;
}

.pera {
  width: 40%;
  margin: 5vh 3vw;  height: 40%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.pera1 {
  width: 40%;
  margin: 5vh 3vw;
    height: 40%;
  display: flex;
  align-items: center;
  flex-direction: column;
}

p {
  margin-top: 4vh;
  width: 250px;
  font-family: Roboto;
}


.Image,
.Image2,
.Image3,
.Image4 {
  text-align: right;
  overflow: hidden;
  height: auto;
}


.img,
.img1 {
  width: 31%;
  margin: 0 3px;
  opacity: 0;
  animation: slideIn 0.5s ease forwards;
  filter: brightness(90%);
}

@keyframes slideIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@keyframes slide {
  0% { transform: translateX(0); }
  25%, 35% { transform: translateX(0); }
  45%, 55% { transform: translateX(-10%); }
  65%, 75% { transform: translateX(-20%); }
  85%, 90%, 100% { transform: translateX(-40%); }
}

.img:hover,
.img1:hover {
  transform: scale(1.1);
}


.servs {
  width: 100%;
  height: 40px;
  background-color: rgb(125, 210, 234);
  margin-bottom: 10px;
}

.servs h2 {
  margin-top: 0%;
  margin-left: 40%;
  font-family: serif;
  color: rgb(255, 111, 0);
  font-size: 30px;
}

.servs li {
  list-style: none;
}


.footer {
  background-color: #222;
  color: #fff;
  padding: 20px;
  text-align: center;
}

.footer-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footer-logo img {
  width: 100%;
  border-radius: 50%;
}

.footer-links a {
  color: inherit;
  text-decoration: none;
  margin-right: 20px;
}

.footer-copyright {
  margin-top: 10px;
}

.back-to-top {
  position: fixed;
  width: 50px;
  height: 50px;
  right: 40px;
  bottom: 30px;
  z-index: 99;
}

.btnnn {
  position: fixed;
  top: 90%;
  left: 50.5%;
  width: 6vw;
  font-size: 40px;
  color:  rgb(255, 111, 0);
  background-color: transparent;
  padding: 20px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1;
}

.btnnn-square,
.btn-sm-square,
.btn-md-square,
.btn-lg-square {
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: normal;
}

.whatsapp_btn {
  position: fixed;
  top: 90%;
  left: 0.5%;
  width: 6vw;
  background-color: transparent;
  padding: 20px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1;
}



  @media screen and (min-width: 100px) and (max-width: 768px) {
    
  .hhhh {
    width: 100%;
    height: auto; 
  }

  .img,
  .img1 {
    width: 60%;
  }

  .logo1 {
    width: 15vw;
  }

  .whatsapp_btn {
    width: 10vw;
  }
  .btnnn{
    width: 10vw;
  }
  .pera1 {
    width: 100%;
    
}
.pera {
  width: 100%;
  
}  .pera2 {
  width: 100%;
  
}

p {
  margin-top: 4vh;
   width: 100%;
  font-family: Roboto;
}

  #Container2,
  #Container3 {
    flex-wrap:wrap;
    justify-content: space-between;
  }

}

.cdscerf52cffdv{
color:white;
text-decoration: none;
font-size: 25px;
}



@media screen and (max-width: 624px) {
  .footer-copyright{
    font-size: 3vw;
  }
  .cdscerf52cffdv{
    font-size: 2vw;
    color:white;
text-decoration: none;
  }
  .footer-links{
  width: 10vw;
  display: grid;
  gap: 9px;
  }
}
@media screen and (max-width: 480px) {
  .hhhh {
    max-width: 100%;
  }

  .img,
  .img1 {
    width: 90%;
  }

  .logo1 {
    width: 20vw;
  }

  .whatsapp_btn {
    width: 15vw;
  }
  .btnnn{
    width: 15vw;
  }

  #Container2,
  #Container3 {
    justify-content: center !important;
    text-align: center; 
  }

 
}

@media screen and (max-width: 320px) {
  .hhhh {
    max-width: 100%;
  }

  .img,
  .img1 {
    width: 90%;
  }

  .logo1 {
    width: 25vw;
  }

  .whatsapp_btn {
    width: 20vw;
  }

  .btnnn{
    width: 20vw;
  }
 
  #Container2,
  #Container3 {
    justify-content: center !important;
    text-align: center; 
  }

}
