#main0 {
  position: relative;
}

.video-container {
  position: relative;
}

.vid {
  width: 100%;
  height: 90vh;
  object-fit: cover;
}

.form-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(255, 255, 255, 0.121);
  padding: 20px;
  border-radius: 5px;
  width: 400px;
  backdrop-filter: blur(2px);
}

.form-overlay h1 {
  margin-top: 0;
  font-size: 50px;
  padding: 20px;
}

.form-group {
  margin-bottom: 10px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  color: rgb(255, 111, 0);
  font-size: 30px;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 8px;
  border-radius: 3px;
  background-color: transparent;
  background-color: #fff;
}

button {
  padding: 8px 16px;
  background-color: #d51010;
  color: #fff;
  border: none;
  border-radius: 3px;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}

.page2 {
  width: 100%;
  height: 200%;
  background-color: #e35a8c;
}

@media screen and (min-width: 50px) and (max-width: 425px) {
  .form-overlay {
    width: 90vw;
  }
  .form-overlay h1 {
    font-size: 12vw;
  }
}





*{
  margin:0;
  padding:0;
  box-sizing:border-box;
}

.contact-parent{
  background: #fff;
  display:flex;
  margin:80px 0;
}

.contact-child{
  display:flex;
  flex:1;
  box-shadow:0px 0px 10px -2px rgba(0,0,0,0.75);
}

.child1{
  background:linear-gradient(rgba(0,0,0,0.7), rgba(0,0,0,0.7)), url("https://cdn.pixabay.com/photo/2019/06/28/00/17/architecture-4303279_1280.jpg");
  background-size:cover;
  display:flex;
  flex-direction:column;
  justify-content:space-around;
  color:#fff;
  padding:100px 0;
}

.child1 p{
  padding-left:20%;
  font-size:20px;
  text-shadow:0px 0px 2px #000;
}

.child1 p span{
  font-size:16px;
  color:#9df2fd;
}

.child2{
  flex-direction:column;
  justify-content:space-around;
  align-items:center;
}

.inside-contact{
  width:90%;
  margin:0 auto;
}

.inside-contact h2{
  text-transform:uppercase;
  text-align:center;
  margin-top:50px;
}

.inside-contact h3{
  text-align:center;
  font-size:16px;
  color:#0085e2;
}

.inside-contact input, .inside-contact textarea{
  width:100%;
  background-color:#eee;
  border:1px solid rgba(0,0,0,0.48);
  padding:5px 10px;
  margin-bottom:20px;
}

.inside-contact input[type=submit]{
  background-color:#000;
  color:#fff;
  transition:0.2s;
  border:2px solid #000;
  margin:30px 0;
}

.inside-contact input[type=submit]:hover{
  background-color:#fff;
  color:#000;
  transition:0.2s;
}

@media screen and (max-width:991px){
  .contact-parent{
      display:block;
      width:100%;
  }

  .child1{
      display:none;
  }

  .child2{
      background-image:linear-gradient(rgba(255,255,255,0.7),rgba(255,255,255,0.7)), url("https://cdn.pixabay.com/photo/2019/06/28/00/17/architecture-4303279_1280.jpg");
      background-size:cover;
  }

  .inside-contact input, .inside-contact textarea{
      background-color:#fff;
  }
}