
nav {
  position: sticky;
  top: 0;
  display: flex;
  justify-content: space-between;
  padding: .5vw 30px;
  background-color: #645e5e63;
  padding: 10px 20px;
  z-index: 1000;
}

.nav-bar li a {
  color: black; 
  text-decoration: none; 
  font-size: 20px;
  font-family: Roboto, Helvetica, sans-serif
}
.logo {
  height: 50px;
}

.nav-bar {
  list-style-type: none;
  margin: 0;
  align-items: center;
  padding: 0;
  display: flex;
}

.nav-bar li {
  margin-right: 20px;
}

.nav-bar li:last-child {
  margin-right: 0;
}

.nav-bar li a {
  text-decoration: none;
  color: #333;
  font-weight: bold;
}

.menu-icon {
  display: none;
}

@media (max-width: 768px) {
  .nav-bar {
    flex-direction: column;
    display: none;
  }

  .menu-icon {
    display: block;
    color: black;
    width: 32px;
    cursor: pointer;
}
.bar{
  color: black;
border: 3px solid rgb(35, 35, 142);
}

  .menu-icon.open .bar:nth-child(1) {
    transform: rotate(-45deg) translate(-5px, 6px);
  }

  .menu-icon.open .bar:nth-child(2) {
    opacity: 0;
  }

  .menu-icon.open .bar:nth-child(3) {
    transform: rotate(45deg) translate(-5px, -6px);
  }

  .nav-bar.open {
    display: block;
    padding-left: 20px;
  }
}



.footer-links a {
  color: #fff;
  text-decoration: none;
  margin-right: 15px;
}

.footer-links a:hover {
  text-decoration: underline;
}


.footer-links i {
  font-size: 20px;
  margin-right: 10px;
  
}

.f3{
font-size: 1vw;
text-align: end;

}

@media (max-width: 768px){
  .ddaadd{
    font-size: 7px;
  }
}